export default {
    api: {
        // baseUrl: 'https://stagingapi.advancerenglish.com',
        baseUrl: 'https://api.advancerenglish.com',

        // baseUrl: 'http://172.31.3.146:6003',
        // baseUrl: 'http://192.168.1.40:9000'
        // baseUrl: 'http://103.31.104.122:9000'

        // baseUrl: 'https://advancer.ml'
        // baseUrl: 'http://127.0.0.1:3100'
    },
}
