import axios, { AxiosError, AxiosResponse } from 'axios'
import * as Services from '@mohsininsignia/advancerapiclient'
import config from '../config'
import { localStorageHelper } from '@/helpers/localStorage'
import Vue from 'vue'

const onRequestSucceed = (response: AxiosResponse): AxiosResponse => {
    if (typeof response.data.jwt !== 'undefined' && response.data.jwt.length > 0) {
        localStorageHelper.UserJwt = response.data.jwt
    }

    return response
}

const onRequestFailed = async (error: any) => {
    if (error.response?.data.userMessageCode) {
        let title = 'Bazı girdiler hatalıdır'
        switch (error.response.data.userMessageCode) {
            case 'NotImplemented':
                title = 'Metod Henüz Hazır Değil'
                break
            case 'Unhandled':
                title = 'Bilinmeyen Hata'
                break
            case 'OperationNotCompleted':
                title = 'İşlem Tamamlanamadı'
                break
            case 'Unauthorized':
                title = 'Yetkisiz İşlem'
                break
            case 'UserNotExists':
                title = 'Kullanıcı Bulunamadı'
                break
            case 'WordTutorialNotExists':
                title = 'Kelime Bulunamadıo'
                break
            case 'DuplicateEmail':
                title = 'Email daha önce kullanılmış'
                break
            case 'DuplicatePhoneNumber':
                title = 'Telefon numarası daha önce kullanılmış'
                break
            case 'DuplicateDiscountCode':
                title = 'İndirim kodu daha önce kullanılmış'
                break
            case 'UserRoleError':
                title = 'Kullanıcı Rolü Hatası'
                break
            case 'MissingDbData':
                title = 'Veritabanı Hatası'
                break
            case 'UnchangedValue':
                title = 'Değiştirilemeyen Değer'
                break
            case 'EmailOrPasswordInCorrect':
                title = 'Email ya da Şifre Yanlış'
                break
            case 'ImageIsNotSquare':
                title = 'Fotoğraf Kare Olmalıdır'
                break
            case 'MinimumAccountPhotoSize':
                title = 'Fotoğraf Boyutu Çok Küçük'
                break
            case 'PhotoMustBeSquare':
                title = 'Fotoğraf Kare Olmalıdır'
                break
            case 'WordTutorialAlreadyFavorited':
                title = 'Zaten Favorilenmiş'
                break
            case 'WordTutorialAlreadyUnfavorited':
                title = 'Zaten Favori Değil'
                break
            case 'DayOptionAlreadyAdded':
                title = 'Seçenek Zaten Mevcut'
                break
            case 'MinutesPerDayOptionAlreadyAdded':
                title = 'Seçenek Zaten Mevcut'
                break
            case 'TeacherAlreadyUnfavorited':
                title = 'Zaten Favori Değil'
                break
            case 'TeacherAlreadyFavorited':
                title = 'Zaten Favorilenmiş'
                break
            case 'MinimumFifteenMinutes':
                title = 'En Az 15 Dakika Olmalıdır'
                break
            case 'CouldNotReadSrtFile':
                title = 'Altyazı Dosyası Okunamadı'
                break
            case 'YoutubeUrlIsNotValid':
                title = 'Youtube Linki Hatalı'
                break
            case 'CategoryNotFound':
                title = 'Kategori Bulunamadı'
                break
            case 'EmptyWordTutorialCategory':
                title = 'Kategori Boş'
                break
            case 'LiveAppointmentNotFound':
                title = 'Canlı Yayın Bulunamadı'
                break
            case 'RoomNotFound':
                title = 'Canlı Yayın Odası Bulunamadı'
                break
            case 'NoLegalDocumentsFound':
                title = 'Yasal Dökümanlar Bulunamadı'
                break
            case 'UserHasActiveSubscription':
                title = 'Kullanıcının Aktif Aboneliği Mevcut'
                break
            case 'UserHasNotActiveSubscription':
                title = 'Kullanıcının Aktif Aboneliği Yok'
                break
            case 'SubscriptionPlanNotFound':
                title = 'Abonelik Paketi Bulunamadı'
                break
            case 'LostPasswordRecoverCodeIsIncorrect':
                title = 'Kurtarma Kodu Hatalı'
                break
            case 'UserBanned':
                title = 'Kullanıcı Engellenmiş'
                break
            case 'IyzicoSignatureNotValid':
                title = 'Iyzico Entegrasyonunda Hatalı'
                break
            case 'PaymentNotFound':
                title = 'Ödeme Bulunamadı'
                break
            case 'SubscriptionNotFound':
                title = 'Abonelik Bulunamadı'
                break
            case 'DateHasAppointment':
                title = 'Bu Tarihde Randevu Mevcut'
                break
            case 'CreditNotEnough':
                title = 'Kredi Yetersiz'
                break
            case 'CashBackError':
                title = 'Kredi Geri Yüklemesinde Hata'
                break
            case 'AlreadyCancelled':
                title = 'Zaten iptal edilmiş'
                break
            case 'AppointmentCantBeCancelled':
                title = 'Randevu iptal edilemez'
                break
            case 'CancellationOnceAWeek':
                title = 'Randevu Bir Hafta Önceleden İptal Edilmeli'
                break
            case 'UserAlreadyUsedTheFreeCall':
                title = 'Ücretsiz Arama Kullanıldı'
                break
            case 'TeacherNotFound':
                title = 'Öğretmen Bulunamadı'
                break
            case 'TeacherHasNotHaveFreeTime':
                title = 'Öğretmen Müsait Değil'
                break
            case 'TeacherIsNotOnline':
                title = 'Öğretmen Çevrimiçi Değil'
                break
            case 'TeacherIsNotApproved':
                title = 'Öğretmen Onaylı Değil'
                break
            case 'UserHasAppointmentInSevenMinutes':
                title = 'Kullanıcının Yakında Görüşmesi Var'
                break
            case 'CallNotFound':
                title = 'Arama Bulunamadı'
                break
            case 'WordTutorialAlreadyLiked':
                title = 'Kelime zaten beğenilmiş'
                break
        }

        Vue.$toast.error(title)
    } else {
        if (error.response?.status === 401) {
            Vue.$toast.warning('Oturumunuz Sona Erdi. Lütfen Tekrar Giriş Yapın')
            localStorageHelper.UserJwt = null
        } else {
            Vue.$toast.error('İşlem Tamamlanamadı! Lütfen Değerleri Kontrol Ediniz')
        }
    }

    throw error
}

const onRequest = (data: any) => {
    if (localStorageHelper.UserJwt !== null) {
        data.headers.Authorization = 'Bearer ' + localStorageHelper.UserJwt
    }

    return data
}

axios.interceptors.request.use(onRequest)
axios.interceptors.response.use(onRequestSucceed, onRequestFailed)

export const AppInfo = Services.AppInfoApiFactory(undefined, config.api.baseUrl, axios)
export const Appointment = Services.AppointmentApiFactory(undefined, config.api.baseUrl, axios)
export const Chat = Services.ChatApiFactory(undefined, config.api.baseUrl, axios)
export const CustomerService = Services.CustomerServiceApiFactory(undefined, config.api.baseUrl, axios)
export const LegalDocument = Services.LegalDocumentApiFactory(undefined, config.api.baseUrl, axios)
export const Live = Services.LiveApiFactory(undefined, config.api.baseUrl, axios)
export const Payments = Services.PaymentApiFactory(undefined, config.api.baseUrl, axios)
export const Scheduler = Services.SchedulerApiFactory(undefined, config.api.baseUrl, axios)
export const Student = Services.StudentApiFactory(undefined, config.api.baseUrl, axios)
export const Teacher = Services.TeacherApiFactory(undefined, config.api.baseUrl, axios)
export const VideoRecord = Services.VideoRecordApiFactory(undefined, config.api.baseUrl, axios)
export const WordTutorial = Services.WordTutorialApiFactory(undefined, config.api.baseUrl, axios)
export const WordTutorialCategory = Services.WordTutorialCategoryApiFactory(undefined, config.api.baseUrl, axios)
export const Administrator = Services.AdministratorApiFactory(undefined, config.api.baseUrl, axios)

export const CustomerServiceAdministration = Services.CustomerServiceAdministrationApiFactory(undefined, config.api.baseUrl, axios)
export const LegalDocumentAdministration = Services.LegalDocumentAdministrationApiFactory(undefined, config.api.baseUrl, axios)
export const AdministratorAdministration = Services.AdministratorAdministrationApiFactory(undefined, config.api.baseUrl, axios)
export const AppInfoAdministration = Services.AppInfoAdministrationApiFactory(undefined, config.api.baseUrl, axios)
export const AppointmentAdministration = Services.AppointmentAdministrationApiFactory(undefined, config.api.baseUrl, axios)
export const PromotionAdministration = Services.PromotionAdministrationApiFactory(undefined, config.api.baseUrl, axios)
export const SubscriptionAdministration = Services.SubscriptionAdministrationApiFactory(undefined, config.api.baseUrl, axios)
export const TeacherAdministration = Services.TeacherAdministrationApiFactory(undefined, config.api.baseUrl, axios)
export const SendGridEmailTemplates = Services.SendGridEmailTemplatesApiFactory(undefined, config.api.baseUrl, axios)
export const WordTutorialAdministration = Services.WordTutorialAdministrationApiFactory(undefined, config.api.baseUrl, axios)
export const WordTutorialCategoryAdministration = Services.WordTutorialCategoryAdministrationApiFactory(undefined, config.api.baseUrl, axios)
export const WordTutorialSubtitleAdministration = Services.WordTutorialSubtitleAdministrationApiFactory(undefined, config.api.baseUrl, axios)
export const StudentAdministration = Services.StudentAdministrationApiFactory(undefined, config.api.baseUrl, axios)

export const ReferralAdministration = Services.ReferralsApiFactory(undefined, config.api.baseUrl, axios)
export const IyzicoSubscription = Services.IyzicoSubscriptionApiFactory(undefined, config.api.baseUrl, axios)

export const studentsCredit = Services.StudentCreditsApiFactory(undefined, config.api.baseUrl, axios)