import Vue from 'vue'
import Router from 'vue-router'

const TheContainer = () => import('../containers/TheContainer.vue')
const Dashboard = () => import('../views/Dashboard.vue')

const Referral = () => import('../views/Referral/Referral.vue')
const Subscriptions = () => import('../views/Subscriptions/Subscriptions.vue')
const Payroll = () => import('../views/Payroll/Payroll.vue')
const AddPayroll = () => import('../views/Payroll/AddPayroll.vue')
const DetailPayroll = () => import('../views/Payroll/DetailPayroll.vue')
const ManageReferral = () => import('../views/Referral/ManageReferral.vue')

//Öğretmenler
const Teachers = () => import('../views/Teachers/Teachers.vue')
const ManageTeachers = () => import('../views/Teachers/ManageTeachers.vue')
// Öğrenciler
const Students = () => import('../views/Students/Students.vue')
const StudentsCredit = () => import('../views/StudentsCredit/StudentsCredits.vue')
const ManageStudents = () => import('../views/Students/ManageStudents.vue')

//Yöneticiler
const Admins = () => import('../views/Admins/Admins.vue')
const AdminsList = () => import('../views/Admins/AdminsList.vue')

// Paketler
const Packets = () => import('../views/Packets/Packets.vue')
const EditPackets = () => import('../views/Packets/EditPackets.vue')

// Legal Dökümanlar
const LegalDocuments = () => import('../views/LegalDocuments/LegalDocuments.vue')
const EditLegalDocuments = () => import('../views/LegalDocuments/EditLegalDocuments.vue')

//Şikayetler
const Complaints = () => import('../views/Complaints/Complaints.vue')
const MessageComplaints = () => import('../views/Complaints/MessageComplaints.vue')
const DateComplaints = () => import('../views/Complaints/DateComplaints.vue')

//Kategoriler
const Categories = () => import('../views/Categories/Categories.vue')
const ManageCategories = () => import('../views/Categories/ManageCategories.vue')

// Sistem Yönetimi
const DiscountCodes = () => import('../views/DiscountCodes.vue')
const Payments = () => import('../views/Payments.vue')
const Settings = () => import('../views/Settings.vue')

//Contact Form
const ContactForms = () => import('../views/ContactForm/ContactForms.vue')
const ContactFormDetails = () => import ('../views/ContactForm/ContactFormDetails.vue')

//Login
const Login = () => import('../views/Login.vue')

// Send Email to Student 
const SendGridTemplates = () => import('../views/SendGridTemplates/SendGridTemplates.vue')
const SendEmail = () =>import('../views/SendEmail/SendEmail.vue')



const router = new Router({
    mode: 'history',
    linkActiveClass: 'open active',
    routes: [
        {
            path: '/',
            redirect: '/login',
            name: 'Anasayfa',
            component: TheContainer,
            children: [
                {
                    path: 'dashboard',
                    name: 'Anasayfa ',
                    component: Dashboard,
                },
                {
                    path: 'referral',
                    name: 'sevk sistemi ',
                    component: Referral,
                },
                {
                    path: 'subscriptions',
                    name: 'abonelikler ',
                    component: Subscriptions,
                },
                {
                    path: 'Payroll',
                    name: 'Payroll ',
                    component: Payroll,
                },
                {
                    path: 'AddPayroll',
                    name: 'AddPayroll ',
                    component: AddPayroll,
                },
                {
                    path: 'DetailPayroll/:id',
                    name: 'DetailPayroll ',
                    component: DetailPayroll,
                },
                {
                    path: 'SendGridTemplate',
                    name: 'SendGridTemplate ',
                    component: SendGridTemplates,
                },
                {
                    path: 'send-email',
                    name: 'SendEmail ',
                    component: SendEmail,
                },
                {
                    path: 'referral/:id',
                    name: 'Yönlendirmeyi Yönet ',
                    component: ManageReferral,
                },
                {
                    path: 'ogretmenler',
                    name: 'Öğretmenler',
                    component: Teachers,
                },
                {
                    path: 'ogretmeni-duzenle/:id',
                    name: 'Öğretmeni Düzenle',
                    component: ManageTeachers,
                },
                {
                    path: 'ogrenciler',
                    name: 'Öğrenciler',
                    component: Students,
                },
                {
                    path: 'Öğrenci-Kredisi',
                    name: 'Öğrenci Kredisi',
                    component: StudentsCredit,
                },
                {
                    path: 'ogrencileri-yonet/:id',
                    name: 'Öğrencileri Yönet',
                    component: ManageStudents,
                },

                {
                    path: 'indirim-kodlari',
                    name: 'İndirim Kuponları',
                    component: DiscountCodes,
                },
                {
                    path: 'paketler',
                    name: 'Paketler',
                    component: Packets,
                },
                {
                    path: 'paket-duzenle',
                    name: 'Paket Düzenleme',
                    component: EditPackets,
                },

                {
                    path: 'kategoriler',
                    name: 'Kategoriler',
                    component: Categories,
                },

                {
                    path: 'kategorileri-yonet/:id',
                    name: 'Kategorileri Yönet',
                    component: ManageCategories,
                },
                {
                    path: 'legal-dokumanlar',
                    name: 'Legal Dökümanlar',
                    component: LegalDocuments,
                },
                {
                    path: 'legal-dokuman-duzenle/:id',
                    name: 'Legal Döküman Düzenleme',
                    component: EditLegalDocuments,
                },
                {
                    path: 'yoneticiler',
                    name: 'Yöneticiler',
                    component: Admins,
                },
                {
                    path: 'yonetici-liste/:id',
                    name: 'Yönetici Listesi',
                    component: AdminsList,
                },
                {
                    path: 'sikayetler',
                    name: 'Şikayetler',
                    component: Complaints,
                },
                {
                    path: 'mesajlasma-sikayetleri/:id',
                    name: 'Mesajlaşma Şikayetleri',
                    component: MessageComplaints,
                },
                {
                    path: 'randevu-sikayetleri/:id',
                    name: 'Randevu Şikayetleri',
                    component: DateComplaints,
                },
                {
                    path: 'odemeler',
                    name: 'Ödemeler',
                    component: Payments,
                },
                {
                    path: 'ayarlar',
                    name: 'Ayarlar',
                    component: Settings,
                },
                {
                    path: 'iletisim-formlari',
                    name: 'İletişim Formlari',
                    component: ContactForms,
                },
                {
                    path: 'iletisim-formu-detaylari',
                    name: 'İletişim Formu Detayları',
                    component: ContactFormDetails,
                },
            ],
        },
        {
            path: '/',
            redirect: '/login',
            name: 'LoginBase',
            component: {
                render(c) {
                    return c('router-view')
                },
            },
            children: [
                {
                    path: 'login',
                    name: 'Login',
                    component: Login,
                },
            ],
        },
    ],
})

export default router
