import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import Prism from 'prismjs'
import moment from 'moment'
import router from './router'
import Router from 'vue-router'
import Croppa from 'vue-croppa'
import { store } from './store'
import Vuelidate from 'vuelidate'
import vueDebounce from 'vue-debounce'
import VueTelInput from 'vue-tel-input'
import CoreuiVue from '@coreui/vue/src'
import VueSweetalert2 from 'vue-sweetalert2'
import * as Api from './services/api-service'
import VueToast from 'vue-toast-notification'
import { freeSet as icons } from '@coreui/icons'
import VueMarkdownEditor from '@kangc/v-md-editor'
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'

import 'moment/locale/tr'
import 'prismjs/components/prism-json'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-tel-input/dist/vue-tel-input.css'
import 'vue-toast-notification/dist/theme-sugar.css'
import '@kangc/v-md-editor/lib/style/base-editor.css'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'

moment.locale('tr')

declare module 'vue/types/vue' {
    interface Vue {
        $api: typeof Api
    }
}

VueMarkdownEditor.use(vuepressTheme, {
    Prism,
})

Vue.filter('formatDate', function(value: any) {
    if (value) {
        return moment(String(value)).format('ll')
    }
})

Vue.filter('formatDateHour', function(value: any) {
    if (value) {
        return moment(String(value)).format('lll')
    }
})

Vue.use(Croppa)
Vue.use(Router)
Vue.use(CoreuiVue)
Vue.use(Vuelidate)
Vue.use(VueTelInput)
Vue.use(VueSweetalert2)
Vue.use(VueMarkdownEditor)
Vue.use(vueDebounce, { fireOnEmpty: true })
Vue.use(VueToast, { position: 'top-right', duration: 2000, pauseOnHover: false })

Vue.config.productionTip = false
Vue.prototype.$api = Api
Vue.prototype.$sweetAlert = Api

new Vue({
    router,
    store,
    icons,
    render: h => h(App),
}).$mount('#app')
